import React from 'react'
import './Styles.css'

const projectsData = [
  {
    title: 'Todae Fashion',
    description: `Todae Fashion a mobile marketplace where users can purchase directly from sustainable clothing brands 
    through an interactive, social media experience that allows people to follow users and brands as well as upload 
    pictures of themselves modeling their favorite outfits while tagging the individual items. This is paired with a 
    personalized recommendation algorithm that provides users with outfit recommendations tailored to their specific 
    style. The platform consists of a client-side web app where clothing brands can manage inventory and track and 
    fulfil orders, as well as a mobile app for consumers. App is available for download on the Google Play Store and 
    on TestFlight for iOS.`,
    image: require('./Assets/todae2.png'),
    link: {
      active: true,
      url: 'https://www.todaefashion.com'
    },
    technologies: ['React JS', 'React Native', 'Redux', 'Firebase']
  },
  {
    title: 'Hygiene Merit Certificates',
    description: `Created for a marketing campaign, Hygiene Merit Certificates are a way to showcase a client's 
    cleanliness and Hygiene Merit's work. Client specific QR codes are printed out on a 'disinfection certificate' 
    for clients to showcase. These QR codes can be scanned to redirect the user to a client specific profile showcasing 
    video evidence of appropriate site disinfection, as well as information about the specific equipment and chemicals 
    used in the process.`,
    image: require('./Assets/hm2.png'),
    link: {
      active: true,
      url: 'https://certificates.hygienemerit.com/#/legoland'
    },
    technologies: ['React JS', 'Firebase']
  },
  {
    title: 'Hierarchical Task Trees',
    description: `Headed data visualization efforts for new project involving investigating the use of NLP models to 
    compile hierarchical task trees modeling goal inputs with a series of goal-step relations to increase helpfulness 
    of AI voice assistants. The demo is powered by a Ruby on Rails backend. The NLP model’s outputs were loaded 
    into an SQL database and the frontend was built using React to visualize the model-generated trees.`,
    image: require('./Assets/wiki-trees.png'),
    link: {
      active: true,
      url: 'https://wikihow-trees.web.app/'
    },
    technologies: ['Python', 'JavaScript', 'Ruby on Rails', 'React JS']
  },
  {
    title: 'PennInTouch Lite',
    description: `Created for my Ruby on Rails course, PennInTouch Lite recreates a simplified version of Penn's 
    official course select system. Users can create an account, instructors can create courses, and students can 
    register for courses`,
    image: require('./Assets/pennlite.png'),
    link: {
      active: true,
      url: 'https://salty-retreat-15259.herokuapp.com/'
    },
    technologies: ['Ruby on Rails']
  }
]

export default function Works(props) {
  const size = props.size
  const bp = props.breakpoint
  const desktop = size >= bp

  function textFromArray(arr) {
    let txt = " "
    for (let i = 0; i < arr.length; i++) {
      txt = txt + arr[i] + "; "
    }
    return txt.slice(0, txt.length - 2) + " "
  }

  return(
    <div style={styles.root}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: desktop ? '60%' : '90%'}}>
        {projectsData.map((projects) => (
          <div style={{display: 'flex', justifyContent: 'center', marginTop: 35, alignItems: 'flex-start'}}>
            <div style={{flex: 1, overflow: 'hidden'}} >
              <img src={projects.image} style={{width: '100%'}} className={'image'}/>
            </div>
            <div style={{marginLeft: 25, textAlign: 'left', flex: 2}}>
              <h3 style={{color: '#434343', marginTop: 0}}>{projects.title}</h3>
              <text style={{fontFamily: 'librebaskerville-italic'}}>{projects.link.active ? 'active' : 'old'}</text>
              <br/>
              <text>{projects.description}</text>
              <br/>
              <p style={{fontFamily: 'librebaskerville-italic'}}>[{textFromArray(projects.technologies)}]</p>
              <div style={{display: 'flex'}}>
                <a href={projects.link.url} style={{marginRight: 15}}>View</a>
                {projects.title === 'Todae Fashion' ?
                  <a href={"https://testflight.apple.com/join/c3CRX3Ua"}>Download App</a> : null
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  root: {
    justifyContent: 'center',
    height: 'auto',
    paddingBottom: 50,
    display: 'flex',
  },
}
