import React, {useCallback, useEffect, useState} from 'react'
import phone from './Assets/todaephone.png'
import {useSpring, animated, useTransition} from 'react-spring'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './Styles.css'
import {Link as LinkR} from "react-scroll";
import java from './Assets/java.png'
import javascript from './Assets/javascript.png'
import react from './Assets/react.png'
import ocaml from './Assets/ocaml.png'
import ruby from './Assets/ruby.png'
import rails from './Assets/rails.png'
import python from './Assets/python.png'
import c from './Assets/c.png'
import GitHubIcon from '@material-ui/icons/GitHub';

export default function Banner(props) {
  const desktop = props.size >= props.bp
  const ipad = props.size < props.bp && (props.size > props.breakpoint)

  const [index, set] = useState(0)
  //const onClick = useCallback(() => set(state => (state + 1) % 4), [])
  const transitions = useTransition(index, p => p, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
  })

  const pages = [
    ({ style }) =>
      <animated.div style={style}>
        <img src={java} style={{height: 350}}/>
      </animated.div>,
    ({ style }) =>
      <animated.div style={style}>
        <img src={python} style={{height: 350}}/>
      </animated.div>,
    ({ style }) =>
      <animated.div style={style}>
        <img src={c} style={{height: 350}}/>
      </animated.div>,
    ({ style }) =>
      <animated.div style={style}>
        <img src={javascript} style={{height: 350}}/>
      </animated.div>,
    ({ style }) =>
      <animated.div style={style}>
        <img src={react} style={{height: 350}}/>
      </animated.div>,
    ({ style }) =>
      <animated.div style={style}>
        <img src={ocaml} style={{height: 350}}/>
      </animated.div>,
    ({ style }) =>
      <animated.div style={style}>
        <img src={ruby} style={{height: 350}}/>
      </animated.div>,
    ({ style }) =>
      <animated.div style={style}>
        <img src={rails} style={{height: 350}}/>
      </animated.div>,
  ]

  function increase() {
    set(state => (state + 1) % pages.length)
  }

  useEffect(() => {
    setTimeout(increase, 3500)
  }, [index])

  const topdown = useSpring({
    top: 0,
    from: { top: -1000 },
  })
  const downup = useSpring({
    top: 0,
    from: { top: 1000 },
  })
  const fade = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  })

  function banner() {
    if (desktop) {
      return (
        <div style={{overflow: 'hidden', width: '100vw'}}>
          <div style={{display: 'flex'}}>
            <div style={styles.main}>
              <div style={{flex: 1}}/>
              <div style={{display: 'flex'}}>
                <div style={{
                  marginLeft: 100,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  maxWidth: '40vw',
                  textAlign: 'left'
                }}>
                  <animated.text style={{
                    fontFamily: 'Raleway',
                    fontSize: 75,
                    justifySelf: 'center',
                    color: '#313131',
                    position: 'relative', ...props, ...topdown
                  }}>Ricardo Gonzalez
                  </animated.text>
                  <animated.p
                    style={{color: '#838c95', textAlign: 'left', ...downup, position: 'relative', marginLeft: 5}}>
                    I am an undergraduate student at the University of Pennsylvania pursuing degrees <br/>in computer
                    science as well as
                    economics with a concentration in finance.
                  </animated.p>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <animated.a href={'https://www.linkedin.com/in/ricardo-agm/'}
                                style={{position: 'relative', marginRight: 10, ...downup}}>
                      <LinkedInIcon style={{color: '#313131', cursor: 'pointer'}} fontSize={'large'}
                                    className={'customButton'}/>
                    </animated.a>
                    <animated.a href={'https://github.com/ricardo-agz'}
                                style={{position: 'relative', ...downup}}>
                      <GitHubIcon style={{color: '#313131', cursor: 'pointer', fontSize: 29}}// fontSize={'large'}
                                  className={'customButton'}/>
                    </animated.a>
                  </div>
                </div>
              </div>

              <div style={{flex: 1}}/>
            </div>
            <div className="simple-trans-main" style={{height: '100%'}}>
              {transitions.map(({item, props, key}) => {
                const Page = pages[item]
                return <Page key={key} style={props}/>
              })}
            </div>
          </div>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <LinkR activeClass="active" to={"test1"} spy={true} smooth={true} duration={500}>
              <div className={'expandIcon'} style={{cursor: 'pointer'}}>
                <ExpandMoreIcon style={{color: 'white'}}/>
              </div>
            </LinkR>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{paddingTop: 100, overflow: 'hidden', width: '100vw'}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'}}>
            <animated.text style={{fontFamily: 'Raleway', fontSize: 75, justifySelf: 'center', color: '#313131', position: 'relative', ...props, ...topdown}}>Ricardo Gonzalez</animated.text>
            <animated.p style={{color: '#838c95', textAlign: 'center', ...downup, position: 'relative', maxWidth: '80vw'}}>
              I am an undergraduate student at the University of Pennsylvania pursuing degrees in computer science as well as
              economics with a concentration in entrepreneurship management.
            </animated.p>
            <animated.a href={'https://www.linkedin.com/in/ricardo-gonzalez-9a6b56145/'} style={{position: 'relative', ...downup}}>
              <LinkedInIcon style={{color: '#313131', cursor: 'pointer'}} fontSize={'large'} className={'customButton'}/>
            </animated.a>
          </div>

          <div className="simple-trans-main-mobile" style={{height: 350, width: '100vw', overflow: 'hidden'}}>
            {transitions.map(({ item, props, key }) => {
              const Page = pages[item]
              return <Page key={key} style={props} />
            })}
          </div>

          {/*<div style={{height: 500}}/>*/}


          <LinkR activeClass="active" to={"test1"} spy={true} smooth={true} duration={500}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className={'expandIcon'} style={{cursor: 'pointer', position: 'static', marginBottom: 50}}>
                <ExpandMoreIcon style={{color: 'white'}}/>
              </div>
            </div>
          </LinkR>
        </div>
      )
    }
  }

  return (
    banner()
  )
}

const styles = {
  main: {
    height: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  bannerContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    flex:1,
    minHeight: '40vh',
  },
  imageMobile: {
    height: '100%'
  },
  bannerContainer: {
    flex: 1,
    margin: 25
  },
  image: {
    height:'100vh',
    font: 'Releway',
  },
  ipadImage: {
    flex: 1,
  },
  ipadContainer: {
    flex: 1,
  }
}
