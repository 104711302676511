import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
//import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import PublicIcon from '@material-ui/icons/Public';
import Button from "@material-ui/core/Button";
//import minibanner4 from "./Assets/minibanner4.png";
//import minibanner3 from "./Assets/minibanner3.png";
import Container from "@material-ui/core/Container";

export default function Contact(props) {
  function handleEmail(e){
    e.preventDefault()
    window.open("mailto:info@todaefashion.com?&subject="+e.target.elements.subject.value+"&body="+e.target.elements.name.value+" - "+e.target.elements.message.value)
  }

  const size = props.size
  const breakpoint = props.breakpoint
  const ipadSize = props.bp

  const desktop = size >= ipadSize

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{width: desktop ? '60%' : '90%'}}>
        <form className={'form'} noValidate autoComplete="off" style={{display: 'flex'}} onSubmit={(e)=>{handleEmail(e)}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', width: 50, marginRight: desktop ? 100 : 25}}>
            <text style={{transform: 'rotate(-90deg)', fontWeight: 'bold', fontSize: 20}}>Contact</text>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', flex: 2}}>
            <TextField id="standard-basic" label="Name" style={{margin: '0px 0px 20px'}} type="name" name = "name"/>
            <TextField id="standard-basic" label="Email" style={{margin: '0px 0px 20px'}} type="email" name = "email"/>
            <TextField id="standard-basic" label="Subject" style={{margin: '0px 0px 20px'}} type="subject" name = "subject"/>
            <TextField id="standard-basic" label="Message" style={{margin: '0px 0px 20px'}} type="message" name = "message"/>
            <Button variant={'default'} style={{margin: '0px 0px 20px'}} color={'primary'} type="submit">Submit</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

