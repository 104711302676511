import React from 'react';
import './App.css';
import './index.css';
import LandingMain from "./Components/LandingMain";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
          <Switch>
              <Route path="/" component={LandingMain} exact/>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
