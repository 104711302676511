import React from 'react'

const skills = ['Python', 'Java', 'C', 'JavaScript', 'Ruby', 'React JS', 'React Native', 'Ruby on Rails', 'OCaml']

export default function About(props) {
  const size = props.size
  const bp = props.breakpoint
  const desktop = size >= bp

  return(
    <div style={styles.root}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: desktop ? '60%' : '90%'}}>
        <div style={{display: 'flex', width: '100%'}}>
          <div style={{flex: 1, display: 'flex', alignItems: 'flex-start'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <text style={{fontWeight: 'bold', fontSize: 20, marginBottom: 3}}>Education</text>
              <div style={{backgroundColor: '#61dafb', width: '100%', height: 3}}/>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flex: 2}}>
            <text style={{fontWeight: 'bold', fontSize: 25}}>University of Pennsylvania</text>
            <div
              style={{
                display: 'flex',
                alignItems: desktop ? 'center' : 'flex-start',
                flexDirection: desktop ? 'row' : 'column',
                justifyContent: desktop ? 'flex-start' : 'center'
              }}
            >
              <text style={{fontStyle: 'italic', color: '#95a3a3'}}>Computer Science & Finance</text>
              {desktop ?
                <div style={{backgroundColor: '#95a3a3', width: 5, height: 5, borderRadius: 10, margin: 10}}/> : null
              }
              <text style={{color: '#95a3a3'}}>August 2019 - May 2023</text>
            </div>
          </div>
        </div>

        <div style={{width: '100%', height: 0.5, backgroundColor: 'rgba(0, 0, 0, 0.25)', marginTop: 50, marginBottom: 50}}/>

        <div style={{display: 'flex', width: '100%'}}>
          <div style={{flex: desktop ?  1 : null, display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginRight: desktop ? 0 : 50}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <text style={{fontWeight: 'bold', fontSize: 20, marginBottom: 3}}>Languages &</text>
              <div style={{backgroundColor: '#61dafb', width: '100%', height: 3}}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <text style={{fontWeight: 'bold', fontSize: 20, marginBottom: 3}}>Frameworks</text>
              <div style={{backgroundColor: '#61dafb', width: '100%', height: 3}}/>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', flex: 2}}>
            {skills.map(skill => (
              <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                <div style={{width: 10, height: 10, backgroundColor: 'black', borderRadius: 10, marginRight: 15}}/>
                <text style={{fontWeight: 'bold', fontSize: 15}}>{skill}</text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    justifyContent: 'center',
    height: '100%',
    paddingBottom: 50,
    display: 'flex',
  },
}
