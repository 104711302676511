import React, {useEffect, useState, useRef, useLayoutEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import './Styles.css'
import '../index.css'
import Banner from './Banner'
import About from './About'
import Contact from "./Contact";
import {Link as LinkR} from "react-scroll";
import {Element} from 'react-scroll';
import {useSpring, animated} from 'react-spring'
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import Works from "./Works";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Resume from "./Resume";

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}

export default function Pricing() {
  const tabs = ['Home', 'About', 'Resume', 'Works', 'Contact']
  const classes = useStyles();
  const [tab, setTab] = useState('Home')
  const [showMenu, setShowMenu] = useState(false)

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 675;
  const mobile = width < breakpoint
  const bp = 1000;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);
    window.scrollTo(0, 0)
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const leftRight = useSpring({
    left: 25,
    from: { left: -width },
  })

  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [posX, setPosX] = useState(0)

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y > prevPos.y
    if (currPos.y < -150) {
      if (isShow !== hideOnScroll) setHideOnScroll(isShow); console.log(currPos.y)
    }
    setPosX(-currPos.y)
  }, [hideOnScroll])

  useEffect(() => {}, [])

  const hidenav = useSpring({ top: !hideOnScroll ? -65 : 0 })




  return (
    <div>
      <LinkR activeClass="active" className="test0" to="test0" spy={true} smooth={false}/>

      {/* Navbar */}
      {width > breakpoint ?
        <animated.div style={{...styles.nav, paddingRight: 50, ...hidenav}}>
          <div style={{color: 'white', flex: 1, display: 'flex', justifyContent: 'flex-start', marginLeft: 15, cursor: 'pointer'}}>{"< RicardoGonzalez />"}</div>
          {tabs.map((text, index) => (
            <LinkR activeClass="active" to={"test"+index} to={"test"+index} spy={true} smooth={true} duration={500} >
              <text
                className={'navButton'}
                style={{fontFamily: 'Raleway', margin: 20, cursor: 'pointer', color: tab===text?'#61dafb':'white'}}
                onClick={() => setTab(text)}
              >
                {text}
              </text>
            </LinkR>
          ))}
        </animated.div>
        :
        <animated.div style={{...styles.nav, height: showMenu ? 250 : 65, paddingRight: showMenu ? 0 : 25,
          paddingBottom: showMenu ? 50 : 0, ...hidenav}}>
          {!showMenu?
            <div style={{color: 'white', flex: 1, display: 'flex', justifyContent: 'flex-start', marginLeft: 15, cursor: 'pointer'}}>{"< RicardoGonzalez />"}</div>
            : null
          }
          {showMenu ?
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-around'}}>
              <ClearIcon
                className={'navButton'}
                style={{color: 'white', alignSelf: 'flex-end', margin: '10px 25px', cursor: 'pointer'}}
                onClick={() => setShowMenu(false)}
              />
              {tabs.map((text, index) => (
                <LinkR activeClass="active" to={"test"+index} to={"test"+index} spy={true} smooth={true} duration={500}
                  onClick={() => setShowMenu(false)}
                >
                  <text
                    className={'navButton'}
                    style={{fontFamily: 'Raleway', margin: 20, cursor: 'pointer', color: tab===text?'#61dafb':'white'}}
                    onClick={() => setTab(text)}
                  >
                    {text}
                  </text>
                </LinkR>
              ))}
            </div>
            :
            <MenuIcon
              className={'navButton'}
              style={{color: 'white', cursor: 'pointer'}}
              onClick={() => setShowMenu(true)}
            />
          }
        </animated.div>
      }

      {/* Banner */}
      <Element name="test0" className="element">
      <Banner size={width} breakpoint={breakpoint} bp={bp}/>
      </Element>

      {/* About */}
      <Element name="test1" className="element" >
      <div
        //style={{backgroundColor: '#313131'}}
        style={{backgroundColor: '#1e1e1e'}}
      >
          <div style={{height: 50}}/>
          <About size={width} breakpoint={bp}/>
      </div>
      </Element>

      {/* Resume */}
      <Element name="test2" className="element" >
        <div>
          <div style={{height: 50}}/>
          <Resume size={width} breakpoint={bp}/>
        </div>
      </Element>

      {/* Works */}
      <div style={{backgroundColor: 'white', marginBottom: 25}}>
        <div style={{
          height: 100,
          position: 'relative',
          left: mobile ? posX - 1600 : posX - 800,
          top: 40,
          display: 'flex',alignItems: 'center'
        }}>
          <text style={{color: '#61dafb', fontSize: 100, fontWeight: 900,
            //fontFamily: 'Raleway:wght@900'
          }}>projects</text>
        </div>
      </div>

      <Element name="test3" className="element" >
        <div style={{backgroundColor: '#ebeeee'}}>
          <div style={{height: 50}}/>
          <Works size={width} breakpoint={bp}/>
        </div>
      </Element>

      {/* Contact */}
      <Element name="test4" className="element" >
        <div>
          <div style={{height: 50}}/>
          <Contact size={width} breakpoint={breakpoint} bp={bp}/>
          <div style={{height: 50}}/>
        </div>
      </Element>

      <footer className={classes.stickyFooter}>
        <div style={{backgroundColor: '#151515', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <LinkR activeClass="active" to={"test0"} spy={true} smooth={true} duration={500}
                 onClick={() => setShowMenu(false)}
          >
            <div className={'expandIcon'} style={{cursor: 'pointer', position: 'relative'}}>
              <ExpandLessIcon style={{color: 'white'}}/>
            </div>
          </LinkR>
          <a href={'https://www.linkedin.com/in/ricardo-gonzalez-9a6b56145/'}>
            <LinkedInIcon style={{color: '#313131', cursor: 'pointer'}} fontSize={'large'} className={'customButton'}/>
          </a>
          <text style={{color: '#313131', marginBottom: 25, marginTop: 10}}>Made by
            <span style={{fontWeight: 'bold'}}> Ricardo Gonzalez</span>
          </text>
        </div>
      </footer>
    </div>
  );
}




function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://todaefashion.com/">
                Todae
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const styles = {
    button: {
      fontFamily: 'Raleway', margin: 20, cursor: 'pointer'
    },
    nav: {
      width: '100%',
      //backgroundColor: 'rgba(255, 255, 255, 0.85)',
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      height: 50,
      borderWidth: 2,
      border: '0px solid rgba(0, 0, 0, 0.15)',
      borderBottomWidth: 1,
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'flex-end',
      display: 'flex',
      zIndex: 5,
    }
}

const useStyles = makeStyles((theme) => ({
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
      justifySelf: 'flex-start',
      fontFamily: 'Raleway'
    },
    link: {
      margin: theme.spacing(1, 1.5),
      cursor: "pointer"
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
}));
