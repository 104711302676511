import React from 'react'
import ricky from './Assets/ricky.jpg'
import {animated} from "react-spring";

export default function About(props) {
  const size = props.size
  const bp = props.breakpoint
  const desktop = size >= bp

  return(
    <div style={styles.root}>
      <div style={{display: 'flex', justifyContent: 'center', width: desktop ? '60%' : '90%'}}>
        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
          <img src={ricky} style={{borderRadius: 100, width: desktop ? 200 : 100, height: desktop ? 200 : 100}}/>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 2}}>
          <h3 style={{marginTop: 0}}><span style={{color: '#c586c0'}}>about </span><span style={{color: "#4ec9b0"}}>me</span></h3>
          <text style={{color: '#c89178'}}>'''</text>
          <text style={{color: '#c89178', textAlign: 'left'}}>I am an undergraduate student at the University of Pennsylvania pursuing
          degrees in computer science as well as economics with a concentration in finance.</text>
          <text style={{color: '#c89178'}}>'''</text>
          <h3 style={{color: '#9cdcfe'}}>details</h3>
          <text style={{color: '#c89178'}}>'''</text>
          <text style={{color: '#c89178'}}>Ricardo Gonzalez</text>
          <text style={{color: '#c89178'}}>Philadelphia, PA</text>
          <text style={{color: '#c89178'}}>
            <a href={"https://www.linkedin.com/in/ricardo-gonzalez-9a6b56145/"} style={{ textDecoration: 'none'}}>LinkedIn/Ricardo</a>
          </text>
          <text style={{color: '#c89178'}}>'''</text>
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
    paddingBottom: 50
  },
}
